import { Col, Progress, Row } from 'antd';
import { ARYBALLE_COLOR_BLUE, ARYBALLE_COLOR_BLUE_DARK, ARYBALLE_COLOR_CYAN, ARYBALLE_COLOR_GRAY } from '../../utils';

type HumiditygaugeProps = {
  humidityValue: number;
};

export const HumidityGauge: React.FC<HumiditygaugeProps> = ({ humidityValue }) => {
  return (
    <>
      <Row justify="center" style={{ marginBottom: 30 }}>
        <Progress
          type="dashboard"
          percent={humidityValue}
          format={() => (
            <>
              <p style={{ color: ARYBALLE_COLOR_BLUE_DARK }}>{humidityValue.toString()}</p>
              <p style={{ fontSize: 14, color: ARYBALLE_COLOR_BLUE_DARK }}>Humidity</p>
            </>
          )}
          gapDegree={60}
          strokeColor={{
            '0%': ARYBALLE_COLOR_CYAN,
            '30%': ARYBALLE_COLOR_CYAN,
            '70%': ARYBALLE_COLOR_BLUE,
            '99%': ARYBALLE_COLOR_BLUE_DARK,
            '120%': ARYBALLE_COLOR_CYAN,
          }}
          trailColor={ARYBALLE_COLOR_GRAY}
          width={250}
        />
      </Row>
    </>
  );
};
